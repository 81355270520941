import { defineComponent, ref, onMounted } from 'vue';
export default defineComponent({
  name: 'Home',
  setup: function setup() {
    var show = ref(false);
    var url = "https://bcepd-dev.atuniversity.cn/z_res/paintbook/video.mp4";

    var handleVideo = function handleVideo() {};

    var handleClose = function handleClose() {
      show.value = false;
    };

    onMounted(function () {
      window.scrollTo(0, 0);
    });
    return {
      handleVideo: handleVideo,
      show: show,
      url: url,
      handleClose: handleClose
    };
  }
});